export const defaultPharmacyCategories = [
  'oxygen accessories',
  'optics accessories',
  'rehabilitation accessories',
  'water activators',
  'breathalyzers',
  'hand sanitizers',
  'magnetic therapy devices',
  'medicine storage boxes',
  'baby nasal aspirators',
  'bandages and orthoses',
  'shoe covers',
  'bandaging materials',
  'bioenergy massagers',
  'virus blockers',
  'inflammation and infections',
  'verticalizers',
  'boost immunity',
  'ramps',
  'conductive gels',
  'hygienic garment liners',
  'gynecological tests',
  'obstetrics and gynecology',
  'gypsum and tires',
  'voice devices',
  'heating pads',
  'disinfectants',
  'defibrillators',
  'diagnostic equipment',
  'stomach intestines liver',
  'medical face masks',
  'masks and glasses for newborns',
  'vision',
  'nebulizers',
  'insulin pumps',
  'feces bag',
  'disinfection germicidal lamps',
  'oxygen cylinders',
  'skin hair nails',
  'hearing aid accessories',
  'medical compression hosiery',
  'contact lenses',
  'analysis containers',
  'oxygen concentrators',
  'orthopedic products',
  'posture correctors',
  'patient care products',
  'cosmetical tools',
  'wheelchairs',
  'medical functional beds',
  'glassware',
  'laboratory centrifuge',
  'enteral nutrition',
  'healing creams',
  'lenses for glasses',
  'lubricants',
  'accessories for blood pressure monitors',
  'massage cups',
  'massage beds',
  'medical tables',
  'disposable trousers',
  'blood glucose monitors',
  'medical gloves',
  'medical thermometers',
  'mobile verticalizers',
  'muscles bones joints',
  'inflatable nursing baths',
  'neurology',
  'nitrate meters',
  'eyeglasses',
  'orthopedic therapy shoes',
  'orthopedic corsets',
  'orthopedic insoles',
  'illuminators and reflectors',
  'computer glasses',
  'medical diapers and sheets',
  'incontinence protective briefs and diapers',
  'condoms',
  'allergy',
  'walkers and rollators',
  'incontinence pads',
  'cold and flu',
  'antimicrobial drugs',
  'anti-decubitus mattresses and pillows',
  'pulse oximeters',
  'soaking solutions',
  'hearing aid consumables',
  'rehabilitation simulators',
  'height meters',
  'disinfectant wipes',
  'sanitary chairs',
  'cardiovascular system',
  'hearing aids',
  'syringes and enemas',
  'surface disinfectants',
  'sterilizers',
  'thermo containers medical',
  'accessories for blood glucose monitors',
  'blood pressure monitors',
  'canes and crutches',
  'allergy devices',
  'ear correctors',
  'physiotherapy equipment',
  'phytoteas and herbal preparations',
  'surgical aspirator',
  'medical caps',
  'syringes needles and injectors',
  'rapid tests for covid-19',
  'endocrinology',
  'products and tools for lice and nits',
  'device for putting on compression stockings',
  'ophthalmology',
  'vitamins',
  'pharmacy',
];
