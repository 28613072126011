export class Endpoint {
  root = '';

  /**
   * Orders
   */
  ordersList = '/rest/customer/orders';
  orderCancel = '/rest/customer/orders/:orderId/cancel';

  constructor(path: string) {
    for (const key of Object.keys(this)) {
      this[key] = path + this[key];
    }
  }
}
