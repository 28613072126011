export class KsKaspiEndpoint {
  static defaultPath = '';

  /**
   * Tires
   */
  getCarMarks = '/yml/car-parts/tyres/car-marks';
  getCarModels = '/yml/car-parts/tyres/car-models';
  getCarYears = '/yml/car-parts/tyres/car-years';
  getCarModifications = '/yml/car-parts/tyres/car-modifications';
  getCarTyreSizes = '/yml/car-parts/tyres/car-tyre-sizes';
  getTireDimensionsByCar = '/yml/car-parts/tyres/find-by-car';

  constructor(path = KsKaspiEndpoint.defaultPath) {
    for (const key of Object.keys(this)) {
      this[key] = path + this[key];
    }
  }
}
