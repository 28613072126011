import { monthlyCalc } from 'ks-utilities/lib/creditCalc';
import formatterNumber from 'ks-utilities/lib/formatterNumber';

import { pipe } from '../../utils/pipe';

function updateMonthlyInstallment(
  card: IItemCard,
  priceLiteral: string,
  monthlyPayment: number
) {
  card.monthlyInstallment.formattedPerMonth = `${formatterNumber(
    monthlyPayment
  )} ${priceLiteral}`;
  return card;
}

function updateCreditMonthlyPrice(
  card: IItemCard,
  _priceLiteral: string,
  monthlyPayment: number
) {
  card.creditMonthlyPrice = monthlyPayment;
  return card;
}

export function mutateMonthlyPayment(card: IItemCard, priceLiteral: string) {
  const monthlyPayment = monthlyCalc(
    card.monthlyInstallment.installment,
    card.unitPrice,
    card.monthlyInstallment.id
  );
  return pipe<IItemCard>(updateMonthlyInstallment, updateCreditMonthlyPrice)(
    card,
    priceLiteral,
    monthlyPayment
  );
}
