import { ListingViewType } from '../../const/ListingViewType';
import { getCurrentCityId } from '../../helpers/locationHelpers';
import { getCategoryHierarchyArr } from './getCategoryHierarchyArr';
import { getCategoryHierarchyProps } from './getCategoryHierarchyProps';
import { getCategoryTags } from './getCategoryTags';
import { getPageCategory } from './getPageCategory';
import { getFromUrl } from './getParamFromSearchQuery';

export function combineListingEventProperties(
  data: IBCatalogGridStoreType | IParamViewedPage
) {
  let parameters = {};

  if (!data) {
    console.error('combineListingEventProperties no data');
    return parameters;
  }
  const listingLayout = data.styleGrid ? data.styleGrid.replace('_', '') : null;

  parameters = {
    shop_city: getCurrentCityId(),
    page_category: getPageCategory(window.location.pathname),
    path: document.location.pathname,
    ref: getFromUrl.ref(),
    referrer: getFromUrl.referrer(),
    utm_campaign: getFromUrl.utmCampaign(),
    sort_by: data.selectedSort,
    result_count: data.total,
    current_page: data.page,
    promoted_by_pcm: data.promotedByPcm,
    listing_layout: listingLayout,
  };

  if (data.treeCategory) {
    const categoryHierarchyArr = getCategoryHierarchyArr(data.treeCategory);
    const categoryHierarchyProps =
      getCategoryHierarchyProps(categoryHierarchyArr);

    parameters = {
      ...parameters,
      ...categoryHierarchyProps,
    };
  }

  if (data.pageType === ListingViewType.search) {
    parameters = {
      ...parameters,
      query_id: data.externalSearchQueryInfo?.queryID,
      search_term: data.searchQuery,
      is_listing_filtered: data.filteredByCategory,
    };
  }

  if (data.treeCategory && data.pageType === ListingViewType.search) {
    const isFinalCategory = !data.categoryTags?.length;
    const hasCategory =
      data.treeCategory.items && data.treeCategory.items.length === 1;
    const appliedRule = data.externalSearchQueryInfo?.appliedRules?.[0];

    parameters = {
      ...parameters,
      is_final_category: isFinalCategory,
      has_category: hasCategory,
      applied_rule: appliedRule?.objectID,
      category_tags: getCategoryTags(data.treeCategory),
    };
  }

  return parameters;
}
