import validator from '../../../utils/validator';

function isValid(date: string) {
  const year = +date.slice(0, 4);
  const month = +date.slice(4, 6);
  const day = +date.slice(6, 8);
  return validator.check.date(year, month, day);
}

export const transformCreatedTime = (date?: string) => {
  if (!date) {
    return;
  }

  if (date.length === 8) { // check date in format 19961216
    if (isValid(date)) {
      return date;
    }
  }
  return new Date(date).toISOString().substring(0, 10).replace(/-/g, '');
};
