export enum PaymentTypes {
  GOLD = 'GOLD',
  RED = 'RED',
  BONUS = 'BONUS',
  CREDIT = 'PAY_WITH_CREDIT',
  CERTIFICATE = 'certificate',
}

export enum OpayPaymentTypes {
  GOLD = 'Gold',
  BONUS = 'Bonus',
  RED = 'Red',
  LOAN = 'Loan',
  REMOTE_LOAN = 'RemoteLoan',
  CERTIFICATE = 'Certificate',
  BUSINESS_ACCOUNT = 'BusinessAccount',
}

export const LoanPaymentTypes = [
  PaymentTypes.CREDIT,

  OpayPaymentTypes.LOAN,
  OpayPaymentTypes.REMOTE_LOAN
];

export const KaspiPaymentTypes = [
  PaymentTypes.GOLD,
  PaymentTypes.RED,
  PaymentTypes.BONUS,

  OpayPaymentTypes.GOLD,
  OpayPaymentTypes.RED,
  OpayPaymentTypes.BONUS,
  OpayPaymentTypes.CERTIFICATE,
  OpayPaymentTypes.BUSINESS_ACCOUNT,
];
