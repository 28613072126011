import { StartedFromBannerKey } from '../const/Common';

export function getStartedFromForSuitableItem() {
  const filtersHaveBeenSettedFromBanner =
    window.ksService.AnalyticService.getSessionData(StartedFromBannerKey);

  return filtersHaveBeenSettedFromBanner ? StartedFromBannerKey : null;
}

export function clearStartedFromForSuitableItem() {
  window.ksService.AnalyticService.removeSessionData(StartedFromBannerKey);
}
