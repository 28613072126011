import { isObject } from 'ks-utilities/lib/isObject';

import { Sentry } from '../../../../sentry';
import { SessionStorageModel } from '../StorageModel/SessionStorageModel';
import { eventSpecDictionary } from './const/EventSpecDictionary';

type TAnalyticEventRawDataParameters = Record<
  string,
  TSimpleTypes | TSimpleTypes[]
>;
type TAnalyticServiceLogHook = (data: IAnalyticEventData) => void;

export interface IAnalyticEventDataParameters {
  [key: string]: string[];
}

export interface IPersonalizeRawData {
  props: Record<string, TSimpleTypes>;
}

export interface IPersonalizeData {
  props: IAnalyticEventDataParameters;
}

export interface IAnalyticEventRawData {
  event: string;
  parameters: TAnalyticEventRawDataParameters;
}

export interface IAnalyticEventData {
  event: string;
  parameters: IAnalyticEventDataParameters;
}

export interface IAnalyticServiceLogger {
  send: (data: IAnalyticEventData) => void;
  setUserProps: (data: IPersonalizeData) => void;
}

/**
 * @desc AnalyticService sending jsonData string
 *
 * @example
 * const json_event = {
 *  "event":"event_name",
 *  "parameters": {"key1":["values"],"key2":["values"], "key3":["values"]}}
 * }
 *
 * Format:
 * @param event - Всегда lowercase разделенный '_' SNAKE CASE ("event_name")
 * @param parameters
 * @value key - Всегда lowercase разделенный '_' SNAKE CASE
 * @value values - Всегда строки и передаются массивом, даже если там один параметр
 *
 */

export class AnalyticService implements IAnalyticService {
  static ServiceName = 'AnalyticService';
  private readonly sessionName = 'analytic';
  private readonly sessionStorageModel = new SessionStorageModel({
    prefix: this.sessionName,
  });
  private readonly logger: IAnalyticServiceLogger;
  private readonly logHooks: TAnalyticServiceLogHook[] = [];
  private userProperties: IPersonalizeData;

  constructor(logger: IAnalyticServiceLogger) {
    this.logger = logger;
  }

  setSessionData(key: string, data: object) {
    this.sessionStorageModel.saveValue({
      name: this.sessionName + key,
      value: JSON.stringify(data),
    });
  }

  getSessionData(key: string): object {
    const data = JSON.parse(
      this.sessionStorageModel.getValue(this.sessionName + key)
    );
    return data;
  }

  removeSessionData(key: string): void {
    this.sessionStorageModel.removeValue(this.sessionName + key);
  }

  log(rawData: IAnalyticEventRawData) {
    try {
      rawData.parameters = this.filterEventParams(rawData);
      const data: IAnalyticEventData = {
        event: rawData.event,
        parameters: this.clearEventParams(rawData.parameters),
      };
      this.logger.send(data);

      this.logHooks.forEach((hook) => hook(data));
    } catch (error) {
      console.warn(`AnalyticService ${rawData.event} error`);
      console.error(error);
      Sentry?.captureException(error);
    }
  }

  addLogHook(logHook: TAnalyticServiceLogHook) {
    this.logHooks.push(logHook);
  }

  personalize(data: IPersonalizeRawData) {
    try {
      const props = this.clearEventParams(data.props);
      this.userProperties = { props };

      this.logger.setUserProps(this.userProperties);
    } catch (error) {
      console.warn('AnalyticService personalize error');
      console.error(error);
      Sentry?.captureException(error);
    }
  }

  getUserProperties(): IPersonalizeData {
    return { ...this.userProperties };
  }

  private filterEventParams(
    data: IAnalyticEventRawData
  ): TAnalyticEventRawDataParameters {
    const eventSpecKeys = eventSpecDictionary[data.event];

    if (!eventSpecKeys) {
      return data.parameters;
    }

    return eventSpecKeys.reduce((obj: object, key) => {
      obj[key] = data.parameters[key];
      return obj;
    }, {});
  }

  private clearEventParams(
    obj: TAnalyticEventRawDataParameters
  ): IAnalyticEventDataParameters {
    if (!isObject(obj)) {
      throw new Error(
        `clearEventParams requires object, got this instead: ${obj}`
      );
    }

    const result = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        return;
      }

      result[key] = Array.isArray(value) ? value : [String(value)];
    });

    return result;
  }
}
