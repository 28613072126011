export function getAdInfo(supplierId: string = null, adsUniqueId?: string) {
  if (supplierId || adsUniqueId) {
    return {
      is_promoted: String(true),
      ads_supplier_id: supplierId,
    };
  }

  let isPromoted = false;
  if (window.m) {
    const params = window.m.parseQueryString(document.location.search);
    supplierId = params.m || null;
    isPromoted = !!params.isPromoted;
  }

  return {
    is_promoted: isPromoted,
    ...(supplierId && { ads_supplier_id: supplierId }),
  };
}

// Extract isPromoted flag from data not from URL
export function getAdInfoFromData(data: IParamProductTeaser) {
  const isPromoted = !!(data.adsSupplierId || data.adsUniqueId);

  return {
    is_promoted: isPromoted,
    ads_supplier_id: data.adsSupplierId,
    supplier: data.product?.supplier,
  };
}
