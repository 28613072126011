export const productURL = 'productURL';
export const cityHasBeenChanged = 'cityHasBeenChanged';
export const useBonusName = 'useBonus';
export const isGeoEnabled = 'isGeoEnabled';
export const userGeoLocation = 'userGeoLocation';
export const searchTerm = 'searchTerm';
export const isCertificate = 'isCertificate';
export const remotePaymentStorageKey = 'remotePayment';
export const hasDeletedReview = 'hasDeletedReview';
export const isReviewEdited = 'isReviewEdited';
