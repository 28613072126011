import { Cookies } from 'ks-utilities/lib/cookies';
import { extendExtract } from 'ks-utilities/lib/extract';

import { logSentryEvent, SentryScopes } from '../../../../sentry';
import { Api, OfferOptions } from '../../api';
import { sortConfigBadges } from '../const/Badge';
import { CertificateType } from '../const/CertificateType';
import { CONSTANTS } from '../const/Constants';
import { GATEWAY } from '../const/Endpoint';
import {
  FilterDeliveryTime,
  FilterDeliveryType,
  filterDeliveryTypeFromTime,
} from '../const/FilterDelivery';
import { magnumId } from '../const/MagnumCodes';
import { QuerySourceType } from '../const/QuerySourceType';
import { getInstallIdForPCM } from '../helpers/getInstallId';
import { defaultCityId, getCurrentCityId } from '../helpers/locationHelpers';
import mutateCardsTitle, {
  addAgeLimitToCard,
} from '../helpers/mutateCardsTitle';
import { mutateMonthlyPayment } from '../helpers/mutateMonthlyPayment';
import { canShowCertificate } from '../pages/item/helpers/canShowCertificate';
import { AvailableZonesApiProvider } from './AvailableZonesApiProvider';
import { getProductListActiveFilters } from './helpers/getProductListActiveFilters';
import { mutateProductCards } from './helpers/mutateProductCards';
import { sortPromoBadges } from './helpers/sortPromoBadges';

export enum ProductListPayloadUi {
  MOBILE = 'm',
  DESKTOP = 'd',
}

export enum ProductListSortValue {
  PRICE_ASC = 'price-asc',
  PRICE_DESC = 'price-desc',
  CREATED_DESC = 'created-desc',
  RATING = 'rating',
  RELEVANCE = 'relevance',
  ASC = 'asc',
}

export interface IProductListPayload {
  q: string;
  sort: ProductListSortValue;
  text?: string;
  ui: ProductListPayloadUi; // desktop or mobile
  i?: string; // installId only mobile app
  u?: string; // userId only mobile app
  c?: string; // city id
  // ? only front usage:
  qs?: QuerySourceType; // searchQuerySource - suggestions from search
  fl?: boolean; // first load
}

export interface IProductListFiltersPayload extends IProductListPayload {
  filteredByCategory: boolean; // is search results by relevant category
  sc?: string; // custom title
}

export interface IProductListResultsPayload extends IProductListPayload {
  page: number;
  promotedItemsRequestId?: string; // id to get ads on next page request
  requestId?: string; // search queryID
}

/**
 * @description Params for filter request
 * @property {String} f Filter name. Example: manufacturerName
 * @property {String} v Search text
 * @property {String} ka
 * Last item in rows. Using for pagination
 * Example: if last element in rows is Skinapple, next "page" will start from Skinapple.
 * Every page contains 250 items
 */
export interface IProductListFilterParams {
  f: string;
  v?: string;
  ka?: string;
  q?: string;
}

class StaticTranslate {
  priceLiteral = 'PRICE_LITERAL';
  today = 'DELIVERY.TODAY';
  tomorrow = 'DELIVERY.TOMORROW';
  have = 'HAVE';
  deliveryExpress = 'DELIVERY.EXPRESS';
  deliveryTeaserTitle = 'DELIVERY.TEASER.TITLE';
  pickupTeaserTitle = 'PICKUP.TEASER.TITLE';
}

// tslint:disable-next-line: max-classes-per-file
export class ProductListApiProvider {
  readonly installId = getInstallIdForPCM();
  readonly userId = window.digitalData?.user?.userId;
  cityId: string = getCurrentCityId() || defaultCityId;
  api: Api;
  m: any;
  t: any = new StaticTranslate();
  checkOffersOptions: {
    merchantId?: string;
    deliveryId?: FilterDeliveryTime;
    sortId?: ProductListSortValue;
  } = {};
  availableZoneProvider: AvailableZonesApiProvider;

  constructor(m: any) {
    this.m = m;
    this.api = new Api(m);

    this.availableZoneProvider = new AvailableZonesApiProvider(m);
  }

  get productListApiProviderTranslate() {
    return {
      priceLiteral: this.m.t.get(this.t.priceLiteral),
      today: this.m.t.get(this.t.today),
      tomorrow: this.m.t.get(this.t.tomorrow),
      have: this.m.t.get(this.t.have),
      deliveryExpress: this.m.t.get(this.t.deliveryExpress),
      deliveryTeaserTitle: this.m.t.get(this.t.deliveryTeaserTitle),
      pickupTeaserTitle: this.m.t.get(this.t.pickupTeaserTitle),
    };
  }

  async getZones() {
    return this.availableZoneProvider.getAvailableZones();
  }

  setCheckOffersOptions({ filters, sortSelector }) {
    const activeFilters = getProductListActiveFilters(filters);
    const activeSortId =
      sortSelector?.find((sort) => sort.selected)?.value || '';
    this.checkOffersOptions = {
      merchantId: activeFilters.merchantId,
      deliveryId: activeFilters.deliveryId,
      sortId: activeSortId,
    };
  }

  async getResults(
    payload: IProductListResultsPayload,
    checkOffers: boolean = true
  ) {
    const zoneIds = await this.availableZoneProvider.getAvailableZones();

    if (zoneIds?.length) {
      zoneIds.forEach((zone) => {
        if (zone) {
          payload.q = `${payload.q || ''}:availableInZones:${zone}`;
        }
      });
    }

    if (this.installId) {
      payload.i = this.installId;
    }

    if (this.userId) {
      payload.u = this.userId;
    }

    if (this.cityId) {
      payload.c = this.cityId;
    }

    this.checkOffersOptions.sortId = payload.sort;

    try {
      const response = await this.m.request({
        // method: 'GET',
        url: GATEWAY.productResults,
        params: payload,
      });

      if (!checkOffers || !response?.data?.length) {
        return response;
      }

      response.data = this.filterByDigitalProducts(response.data);

      await this.updatePriceAndSortCards(response.data);

      this.updateCardsInfo(response.data);

      return response;
    } catch (error) {
      console.error(error);
      logSentryEvent({
        scope: SentryScopes.PRODUCT_LIST,
        message: 'getResults error',
      });
      return null;
    }
  }

  async getFilters(
    payload: IProductListFiltersPayload,
    checkOffers: boolean = true
  ) {
    const zoneIds = await this.availableZoneProvider.getAvailableZones();

    if (zoneIds?.length) {
      payload.q = payload.q || '';

      if (!payload.q.includes('availableInZones')) {
        zoneIds.forEach((zone) => {
          if (zone) {
            payload.q = `${payload.q}:availableInZones:${zone}`;
          }
        });
      }
    }

    if (this.installId) {
      payload.i = this.installId;
    }

    if (this.userId) {
      payload.u = this.userId;
    } else if (
      process.env.NODE_ENV === 'development' ||
      Cookies.get('dev-tools')
    ) {
      payload.u = '5427275';
    }

    if (this.cityId) {
      payload.c = this.cityId;
    }

    try {
      const response = await this.m.request({
        // method: 'GET',
        url: GATEWAY.productFilters,
        params: payload,
        extract: extendExtract,
      });

      if (!response?.data) {
        return null;
      }

      response.data.zoneId = zoneIds;

      // ? needs to refactor all treeCategory.items on null,
      // ? easier to set null to treeCategory
      if (response.data.treeCategory?.items === null) {
        response.data.treeCategory = null;
      }

      // ? encode "+" in redirect
      if (response.redirect) {
        response.redirect = response.redirect.replace(/\+/g, '%2B');
      }

      if (!checkOffers || !response.data.cards?.length) {
        return response;
      }

      this.setCheckOffersOptions(response.data);
      response.data.cards = this.filterByDigitalProducts(response.data.cards);
      await this.updatePriceAndSortCards(response.data.cards);

      this.updateCardsInfo(response.data.cards);

      return response;
    } catch (error) {
      console.error(error);
      logSentryEvent({
        scope: SentryScopes.PRODUCT_LIST,
        message: 'getFilters error',
      });
      return null;
    }
  }

  async getFiltersPlain(params: IProductListFiltersPayload) {
    try {
      const response = await this.m.request({
        url: GATEWAY.productFilters,
        params,
      });

      if (!response) {
        return null;
      }

      return response;
    } catch (error) {
      console.error(error);
      logSentryEvent({
        scope: SentryScopes.PRODUCT_LIST,
        message: 'getFilters plain error',
      });
      return null;
    }
  }

  async getFilter(params: IProductListFilterParams) {
    try {
      const response = await this.m.request({
        url: GATEWAY.productFilter,
        params,
      });

      if (!response) {
        return null;
      }

      return response;
    } catch (error) {
      console.error(error);
      logSentryEvent({
        scope: SentryScopes.PRODUCT_LIST,
        message: 'getFilter error',
      });
      return null;
    }
  }

  updateCardsInfo(cards: IItemCard[]) {
    cards.forEach((card) => {
      if (card.promo?.length > 1) {
        card.promo = sortPromoBadges({
          config: sortConfigBadges,
          badges: card.promo,
        });
      }

      addAgeLimitToCard(card);
      mutateMonthlyPayment(card, this.m.t.get(this.t.priceLiteral));
    });

    mutateCardsTitle(cards, CONSTANTS.mutateTitleCardCategories);
  }

  filterByDigitalProducts(cards: IItemCard[]) {
    if (canShowCertificate()) {
      return cards;
    }

    return cards.filter(
      (card) => card.certificateType !== CertificateType.KASPI_SHOP
    );
  }

  async updatePriceAndSortCards(cards: IItemCard[]) {
    let offers: IOfferGateway[];

    const zoneIds = await this.availableZoneProvider.getAvailableZones();
    const params: any = {};

    if (zoneIds?.length) {
      params.zoneId = zoneIds;
    }

    if (this.checkOffersOptions.merchantId) {
      const payload = {
        ...this.getOffersByMerchantPayload({
          products: cards,
          merchantId: this.checkOffersOptions.merchantId,
        }),
        ...params,
      };

      offers = await this.api.getOffersByMerchant(payload);
    } else if (this.checkOffersOptions.deliveryId) {
      const payload = {
        ...this.getOffersByFilterDeliveryPayload({ products: cards }),
        ...params,
      };

      offers = await this.api.getOffersByFilterDelivery(payload);
    } else {
      if (CONSTANTS.showDeliveryDurationDateInTeaser) {
        const payload = {
          ...this.getOffersByDeliveryDurationPayload({ products: cards }),
          ...params,
        };

        offers = await this.api.getOffersByDeliveryDuration(payload);
      }
    }

    if (!offers) {
      return;
    }

    mutateProductCards({
      products: cards,
      offers,
      merchantId: this.checkOffersOptions.merchantId,
      deliveryKey: this.checkOffersOptions.deliveryId,
      translation: this.productListApiProviderTranslate,
    });

    if (
      [
        ProductListSortValue.PRICE_ASC,
        ProductListSortValue.PRICE_DESC,
      ].includes(this.checkOffersOptions.sortId)
    ) {
      cards.sort((cardA, cardB) =>
        this.checkOffersOptions.sortId === ProductListSortValue.PRICE_ASC
          ? cardA.unitPrice - cardB.unitPrice
          : cardB.unitPrice - cardA.unitPrice
      );
    }
  }

  getOffersBasePayload(): IGetOffersByFilterPayload {
    const payload: IGetOffersByFilterPayload = {
      options: [OfferOptions.PRICE],
      cityId: this.cityId,
    };

    if (this.installId) {
      payload.installationId = this.installId;
    }

    if (this.userId) {
      payload.userId = this.userId;
    }

    return payload;
  }

  getOffersByMerchantPayload({
    products,
    merchantId,
  }): IGetOffersByMerchantPayload {
    const payload = this.getOffersBasePayload() as IGetOffersByMerchantPayload;
    const entries = products.map((product) => ({
      sku: product.id,
      merchantId,
      hasVariants: product.hasVariants,
    }));

    payload.entries = entries;

    if (CONSTANTS.showDeliveryDurationDateInTeaser && merchantId !== magnumId) {
      payload.options.push(OfferOptions.DELIVERY);
    }

    return payload;
  }

  getOffersByFilterDeliveryPayload({
    products,
  }): IGetOffersByFilterDeliveryPayload {
    const payload =
      this.getOffersBasePayload() as IGetOffersByFilterDeliveryPayload;

    if (CONSTANTS.showDeliveryDurationDateInTeaser) {
      payload.options.push(OfferOptions.DELIVERY);
    }

    const entries = products.map((product) => ({
      sku: product.id,
      hasVariants: product.hasVariants,
    }));

    payload.entries = entries;
    payload.deliveryDuration =
      filterDeliveryTypeFromTime[this.checkOffersOptions.deliveryId];

    return payload;
  }

  getOffersByDeliveryDurationPayload({
    products,
  }: {
    products: IItemCard[];
  }): IGetOffersByDeliveryDurationPayload {
    const payload =
      this.getOffersBasePayload() as IGetOffersByDeliveryDurationPayload;
    payload.options.push(OfferOptions.DELIVERY);

    const entries = products.map((product) => {
      let deliveryDuration = product.deliveryDuration;

      if (deliveryDuration) {
        // changing EXPRESS to TODAY
        deliveryDuration =
          product.deliveryDuration === FilterDeliveryType.EXPRESS
            ? FilterDeliveryType.TODAY
            : product.deliveryDuration;

        return {
          sku: product.id,
          deliveryDuration,
        };
      } else {
        return {
          sku: product.id,
        };
      }
    });

    payload.entries = entries;

    return payload;
  }
}
