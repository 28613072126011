export const defaultDiscountsCategories = [
  'women knee boots',
  'women clogs',
  'men boots',
  'girl heeled sandals',
  'men dress shoes',
  'girl dress shoes',
  'women ballet pumps',
  'boy moccasins',
  'boy boots',
  'men sandals',
  'girl moccasins',
  'girl athletic shoes',
  'men athletic shoes',
  'men sneakers',
  'boy athletic shoes',
  'men safety shoes',
  'women heeled sandals',
  'boy trainers',
  'women mules',
  'women safety shoes',
  'sandals for babies',
  'boots for babies',
  'women boots',
  'boy dress shoes',
  'slip-ons for babies',
  'girl slip-ons',
  'boy dance shoes',
  'sneakers and trainers for babies',
  'women hiking shoes',
  'women dress shoes',
  'men espadrilles',
  'girl boots',
  'boy slip-ons',
  'women espadrilles',
  'women flip flops',
  'men moccasins',
  'women moccasins',
  'women ankle boots',
  'girl sneakers',
  'men flip flops',
  'girl sandals',
  'boy sandals',
  'men knee boots',
  'men trainers',
  'women slip-ons',
  'women sandals',
  'girl dance shoes',
  'boy hiking shoes',
  'boy flip flops',
  'men fashion slippers',
  'girl hiking shoes',
  'men dance shoes',
  'flip flops for babies',
  'boy sneakers',
  'women sneakers',
  'women trainers',
  'knee boots for babies',
  'dress shoes and moccasins for babies',
  'girl fashion slippers',
  'fashion slippers for babies',
  'men hiking shoes',
  'boy cardigans',
  'boy national costumes',
  'boy jumpsuits',
  'women dresses for namaz',
  'boy socks',
  'boy trench coats',
  'girl blazers',
  'girl national costumes',
  'women nightwear',
  'baby bodysuits and rompers sets',
  'women pants',
  'footie pants',
  'baby girl dresses',
  'men cardigans',
  'women shirts',
  'girl jumpsuits',
  'girl swimsuit tops',
  'women sheepskin coats',
  'men pullovers',
  'maternity shorts',
  'boy thermal underwear',
  'girl thermal underwear',
  'baby trousers and shorts sets',
  'boy down jackets',
  'boy sport suits',
  'girl hoodies',
  'girl parkas',
  'women sweaters',
  'boy lightweight jackets',
  'maternity suits',
  'girl beach jumpsuits',
  'girl skirts',
  'girl bras',
  'women dresses',
  'girl shirts and blouses',
  'boy pants',
  'maternity cardigans and pullovers',
  'women beach dresses and tunics',
  'boy coats',
  'women stockings',
  'women suits',
  'boy nightwear',
  'men sport suits',
  'boy school suits',
  'baby jumpsuits and sleepsuits sets',
  'maternity pants',
  'baby shirts and blouses',
  'men parkas',
  'girl underpants',
  'women swimsuit bottoms',
  'women skirts',
  'baby jackets',
  'women costumes',
  'girl swimsuit bottoms',
  'boy sweaters',
  'baby sweaters sets',
  'girl clothing sets',
  'girl pants',
  'women thermal underwear',
  'girl costumes',
  'boy swim briefs and shorts',
  'girl lounge t-shirts',
  'women shorts',
  'men house t-shirts',
  'women panties',
  'maternity hoodies',
  'men shirts',
  'women hoodies',
  'maternity t-shirts',
  'women sport suits',
  'boy lounge t-shirts',
  'baby trousers',
  'men fashion vests',
  'women lightweight jackets',
  'men socks',
  'women lounge t-shirts',
  'baby swimwear',
  'girl coats',
  'girl socks',
  'women bodysuits',
  'girl school skirts',
  'boy school shirts',
  'boy school blazers',
  'boy shorts',
  'baby sweaters',
  'baby undershirts',
  'babies hats and caps',
  'men t-shirts',
  'boy pullovers',
  'women fashion vests',
  'maternity jeans',
  'baby shorts',
  'men shorts',
  'men thermal underwear',
  'girl sweaters',
  'women parkas',
  'women tanks and camis',
  'men sweaters',
  'men swim briefs and shorts',
  'maternity bras',
  'boy t-shirts',
  'women blouses',
  'boy shirts',
  'boy outerwear sets',
  'girl tights',
  'men trouser socks',
  'girl swimsuits',
  'children home clothes',
  'men suits',
  'swaddling blankets',
  'women jeans',
  'maternity tanks and camis',
  'women workwear',
  'men down jackets',
  'girl pullovers',
  'boy clothing sets',
  'maternity robes',
  'girl jeans',
  'women fur coats',
  'girl down jackets',
  'baby bodysuits',
  'women house pants',
  'women fur gilets',
  'boy costumes',
  'men national costumes',
  'girl beach dresses',
  'women coats',
  'girl outerwear sets',
  'baby tees',
  'girl cardigans',
  'wedding dresses',
  'men lightweight jackets',
  'girl school suits',
  'women blazers',
  'girl sport suits',
  'women lingerie sets',
  'boy blazers',
  'men robes',
  'baby sleepsuits',
  'maternity underwear sets',
  'men blazers',
  'men pants',
  'rompers',
  'girl school vests',
  'women pullovers',
  'boy underpants',
  'baby socks',
  'men house pants',
  'women trench coats',
  'women bras',
  'girl nightwear',
  'maternity nightwear',
  'men trench coats',
  'girl trench coats',
  'maternity jumpsuits',
  'girl school aprons',
  'girl dresses',
  'women t-shirts',
  'women swimsuits',
  'women jumpsuits',
  'baby tees and undershirts sets',
  'girl school pants',
  'boy jeans',
  'maternity skirts',
  'newborn babies clothing sets',
  'girl lightweight jackets',
  'women leg warmers',
  'women pareos',
  'maternity tights',
  'women tights',
  'girl shorts',
  'footie pants sets',
  'girl school shirts and blouses',
  'boy hoodies',
  'men underpants',
  'women trouser socks',
  'men jeans',
  'girl school dresses',
  'girl school blazers',
  'men coats',
  'workwear',
  'robes for boys',
  'men nightwear',
  'underwear sets for girls',
  'maternity panties',
  'women shapewear',
  'bathrobes for girls',
  'boy fashion vests',
  'men hoodies',
  'women down jackets',
  'girl t-shirts',
  'maternity lightweight jackets',
  'boy parkas',
  'men sheepskin coats',
  'women beach pants',
  'toddler tights',
  'women socks',
  'maternity dresses',
  'women robes',
  'boy school pants',
  'women cardigans',
  'women swimsuit bras',
  'men costumes',
  'boy tights',
  'women national costumes',
  'boy school vests',
  'maternity shirts',
  'girl fashion vests',
  'women underwear sets',
  'baby jumpsuits',
  'maternity down jackets',
  'fashion',
  'shoes',
];
