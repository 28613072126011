export enum Account {
  GOLD = 'gold',
  RED = 'red',
  BONUS = 'bonus',
  CERTIFICATE = 'certificate',
}

export enum AccountType {
  LOAN = 'loan',
  ACCOUNT = 'account',
}
