import { Cookies } from 'ks-utilities/lib/cookies';

import { logSentryEvent, SentryScopes } from '../../../../sentry';
import { LocaleType } from '../services/translation/LocaleType';

enum DictionaryLocale {
  KZ = 'kz',
  RU = 'ru',
}

export const dictionaryCityType = {
  [LocaleType.KZ]: DictionaryLocale.KZ,
  [LocaleType.RU]: DictionaryLocale.RU,
};

export const cityList: ICityRow[] =
  window.BACKEND.components?.citySelection?.citys || [];
export const selectedCityCookieName = 'kaspi.storefront.cookie.city';
export const defaultCityId = '750000000';

export function getCurrentCityId(): string {
  const cityId = Cookies.get(selectedCityCookieName);
  if (!cityId) {
    logSentryEvent({
      scope: SentryScopes.CITY_COOKIE,
      message: "Couldn't get city id from cookie",
    });
  }
  return cityId;
}

export function getCityById(currentCityId: string): ICity {
  return cityList
    .reduce<ICity[]>((acc, current) => [...acc, ...current.cityData], [])
    .find((el) => String(el.id) === String(currentCityId));
}

export function getCityFromCurrentUrl(): string | null {
  const codes = cityList.reduce<string[]>(
    (acc, item) => acc.concat(item.cityData.map((city) => city.code)),
    []
  );
  const cityCode = window.location.pathname.split('/')[2]; // * ["","shop","almaty","c","categories",""];
  return codes.includes(cityCode) ? cityCode : null;
}

export function findCityName(currentCityId: string, cities = []) {
  if (!cities.length) {
    return '';
  }

  const currentCity = cities
    .reduce((acc: any, current: any) => [...acc, ...current.cityData], [])
    .find((el: any) => String(el.id) === String(currentCityId));
  return currentCity?.name || '';
}

export function getCurrentDictionaryCity(
  currentCityId: string,
  cities = [],
  locale = LocaleType.RU
): ICityDictionary {
  if (!cities.length) {
    return null;
  }

  const dictionaryType = dictionaryCityType[locale];
  const currentCity: ICity = cities
    .reduce(
      (acc: ICityRow[], current: ICityRow) => [...acc, ...current.cityData],
      []
    )
    .find((el: ICity) => String(el.id) === String(currentCityId));

  const dictionaryCity = currentCity?.dictionaryList?.find(
    (item) => item.locale === dictionaryType
  );

  return dictionaryCity || null;
}

export const getCurrentCityName = (): string =>
  findCityName(getCurrentCityId(), cityList);
