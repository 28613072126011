// Скошенные ставки , меняющиеся по месяцам для расчет ежемесячного платежа по кредиту
const slashedRates = {
  6: [
    {
      interestRate: 39,
    },
    {
      interestRate: 52.45,
    },
    {
      interestRate: 11,
      term: 4,
    },
  ],
  12: [
    {
      interestRate: 39.5,
    },
    {
      interestRate: 54.7,
    },
    {
      interestRate: 11,
      term: 6,
    },
  ],
  24: [
    {
      interestRate: 39.5,
    },
    {
      interestRate: 55,
    },
    {
      interestRate: 11,
      term: 10,
    },
  ],
};

/**
 * Расчет ежемесячного платежа по кредиту
 * Данный расчет взят с формул из excel таблицы КНП
 *
 * @param amount - сумма кредита
 * @param term - период кредита в месяцах
 * @returns - ежемесячный платеж
 */

export function creditCalc(amount: number, term: number) {
  const rates = slashedRates[term];

  if (!rates || amount <= 0 || term <= 0) {
    return null;
  }

  const DAYS_IN_MONTH = 30;
  const DAYS_IN_YEAR = 360; // Financial convention (30/360): для простоты предполагается, что в году 360 дней.

  let accumulatedMultiplier = 0; // Добавочная переплата за месяц
  let multiplierProduct = 1; // Мультипликатор для начисления процентов
  let totalPayment = 0; // Общая накопительная переплата

  for (let i = 1; i <= term; i++) {
    let rate = 0;
    const lastTerm = rates[2].term;

    // Определение процентной ставки для текущего месяца
    if (i === 1) {
      rate = rates[0].interestRate;
    } else if (i >= 2 && i < lastTerm) {
      rate = rates[1].interestRate;
    } else if (i >= lastTerm) {
      rate = rates[2].interestRate;
    }

    // Рассчет временной переплаты
    accumulatedMultiplier =
      ((rate * DAYS_IN_MONTH) / 100) * (DAYS_IN_MONTH / DAYS_IN_YEAR);

    // Обновление накопленного мультипликатора и общей переплаты
    multiplierProduct *= 1 + accumulatedMultiplier / DAYS_IN_MONTH;
    totalPayment += totalPayment * (accumulatedMultiplier / DAYS_IN_MONTH) + 1;
  }

  // Аннуитетный расчет
  const monthlyPayment = (amount * multiplierProduct) / totalPayment;

  return Math.ceil(monthlyPayment);
}

/**
 * Расчет ежемесячного платежа по рассрочке
 */
export function installmentCalc(amount: number, month: number): number {
  return Math.ceil(amount / month);
}

/**
 * Расчет ежемесячного платежа по рассрочке или кредиту
 * @param isInstallment - Флаг для проверки рассрочка или кредит
 * @param amount - сумма кредита/рассрочки
 * @param term - период кредита в месяцах
 * @returns - ежемесячный платеж
 */
export function monthlyCalc(
  isInstallment: boolean,
  amount: number,
  term: number
) {
  return isInstallment
    ? installmentCalc(amount, term)
    : creditCalc(amount, term);
}
