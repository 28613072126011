import { BffEndpoint } from './endpoint/BffEndpoint';
import { GatewayEndpoint } from './endpoint/GatewayEndpoint';
import { Endpoint } from './endpoint/HybrisEndpoint';
import { KsKaspiEndpoint } from './endpoint/KsKaspiEndpoint';

export const publicPath = window.BACKEND.config.contextPath; // '/shop';
export const bffPath = window.BACKEND.config.bffPath; // '/yml/bff-sf';
export const ksSubdomain = window.BACKEND.config.ksSubdomain; // https://ks.kaspi.kz
export const ENDPOINT = new Endpoint(publicPath);
export const GATEWAY = new GatewayEndpoint();
export const BFF = new BffEndpoint(bffPath);
export const KS_KASPI = new KsKaspiEndpoint(ksSubdomain);
